body {
    margin: 0;
    font-family: 'Poppins', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

p {
    margin: 0;
    padding: 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Header */
.header {
    height: 63vh;
    width: 100%;
    overflow: hidden;
    position: relative;
    background-repeat: no-repeat;
    background-size: contain;
    /*background-color: #F6F7F9;*/
    background-position: right top;
}


.header-package-detail {
    background-image: none;
}

.contact-us-header {
    height: 70vh;
    width: 100%;
    overflow: hidden;
    position: relative;
}

.category-header {
    height: 70px;
    width: 100%;
    overflow: hidden;
    position: relative;
}

.cat-header {
    /*height: 60vh;*/
    width: 100%;
    overflow: hidden;
    position: relative;
}

.category-search {
    max-width: 300px;
    font-size: 14px;
    margin-left: auto;
}

.header-container {
    position: relative;
}

.header-container-sm {
    padding: 0 40px;
}

.terms-header-container-sm {
    padding: 0 40px;
}

/* Navbar */
.navbar {
    height: 70px;
    display: flex;
}

.logo {
    width: 150px;
    cursor: pointer;
    margin: auto auto auto 0;
}

.nav-item {
    margin: auto 0 auto 20px;
    cursor: pointer;
    color: #5a6167;
    font-size: 15px;
    padding: 5px;
}

.nav-item-diff {
    margin: auto 0 auto 20px;
    cursor: pointer;
    color: #5a6167;
    font-size: 15px;
}

.dropdown-nav {
    position: relative;
    border-radius: 10px 10px 0 0;
}

.dropdown-nav:hover {
    background-color: #FFFFFF;
}

.cat-dropdown {
    position: absolute;
    top: 30px;
    left: 12px;
    width: 0;
    height: 0;
    border-radius: 0 10px 10px 10px !important;
    z-index: 20;
    justify-content: center;
    align-items: center;
    align-content: center;
    transition: width 0.1s;
}

.cat-dropdown > .row {
    padding: 10px;
}

.cat-dropdown-item {
    display: none
}

.dropdown-nav:hover > .cat-dropdown > .row > .cat-dropdown-item {
    display: block;
}

.dropdown-nav:hover > .cat-dropdown {
    width: 300px;
    background-color: #FFFFFF !important;
}

.nav-item-no-margin {
    margin: auto 0 !important;
}

.nav-item:hover {
    color: #424b5a;
    font-weight: 500;
}

.nav-item-sm {
    display: none;
}

.nav-btn {
    background-color: white;
    font-weight: 600;
    width: 100px;
    font-size: 13px;
}

.login-btn {
    color: #6CD7BB;
    border: 2px solid #6CD7BB;
}

.login-btn:hover {
    color: white;
    background-color: #6CD7BB;
}

.join-us-btn {
    color: #FFC19A;
    border: 2px solid #FFC19A;
}

.join-us-btn:hover {
    color: white;
    background-color: #FFC19A;
}

@media screen and (max-width: 1200px) {
    .nav-item {
        font-size: 14px;
        margin: auto 0 auto 10px;
    }

    .nav-btn {
        font-size: 12px;
        width: 80px;
        font-weight: 500;
    }
}

@media screen and (max-width: 992px) {
    .logo {
        margin-right: auto;
        width: 120px;
    }

    .nav-item {
        margin: auto 0 auto 20px;
    }

    .nav-item-lg {
        display: none;
    }

    .nav-item-sm {
        display: block;
    }
}

/* Hero */

.hero {
    height: calc(63vh - 100px);
    position: relative;
    display: flex;
    flex-direction: column;
    position: relative;
}

.background-image-color {
    position: absolute;
    right: -20%;
    top: -20%;
    width: 1000px;
    z-index: -10;
}

.hero-img {
    position: absolute;
    z-index: -9;
}

.test-hero-img {
    z-index: -10;
    width: 100%;
    height: 100%;
}

.hero-text {
    font-size: 38px;
    font-weight: 700;
    line-height: 1;
    word-spacing: 3px;
}

.contact-us-hero-text {
    font-size: 40px;
    font-weight: 700;
    line-height: 2;
}

.contact-us-hero-meta {
    font-size: 32px;
    font-weight: 600;
    line-height: 2;
}

.hero-meta {
    font-size: 14px;
    line-height: 2;
    width: 500px;
    margin: 10px 0;
    color: #5a6167;
}

.hero-container {
    height: 100%;
    width: 100%;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin: 0 auto;
}

.hero-information-container {
    margin: auto 0;
}

.sub-cat-hero-sm {
    display: none;
}

.package-detail-hero-text {
    font-size: 12px;
}

.location-container {
    display: flex;
    margin: 0 0 1px 0;
}

@media screen and (max-width: 567px) {
    .location-container {
        margin: 0 0 0px 0;
    }

    .hero-container {
        width: 100%;
    }

    .package-detail-hero-text {
        font-size: 12px;
    }

    .bg-illustration {
        display: none;
    }

    .bg-shape-1 {
        display: none;
    }

    .bg-shape-2 {
        display: none;
    }

    .bg-shape-3 {
        display: none;
    }


    .sub-cat-hero-lg {
        display: none;
    }

    .sub-cat-hero-sm {
        display: block;
    }

    .cat-page-heading {
        display: none;
    }

    /*.cat-header {*/
    /*    height: 170px !important;*/
    /*}*/
}

.package-detail-hero-btn {
    background-color: white;
    font-size: 17px;
    font-weight: 500;
    padding: 0 15px;
    color: #5a6167;
    border: 1px solid #5a6167;
    margin-left: 20px;
    height: 40px;
}

.package-detail-hero-btn > * {
    color: #5a6167;
}

.package-liked-btn {
    background-color: white;
    font-size: 14px;
    font-weight: 400;
    padding: 0 7px;
    color: #5a6167;
    border: 1px solid #5a6167;
    height: 30px;
}

.package-detail-header-container {
    margin: 10px 0 auto 0;
    display: flex;
    flex-direction: column;
}

.hero-location-package-detail {
    margin-bottom: 50px;
}

.hero-heading-package-detail {
    font-size: 35px;
    font-weight: 700;
    margin-right: auto;
    margin-bottom: 10px;
}

.hero-meta-package-detail {
    font-size: 17px;
    font-weight: 500;
}

.shape1-bg-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 20%;
    z-index: -10;
}

.shape2-bg-img {
    position: absolute;
    bottom: 0;
    z-index: -10;
    right: 0;
    width: 40%;
}

.center-bg-img {
    position: absolute;
    bottom: 0;
    z-index: -5;
    left: 30%;
    width: 60%
}

.store-btn-container {
    display: flex;
    margin: 10px 0;
}

.store-btn {
    background-color: black;
    display: flex;
    padding: 10px;
    text-decoration: none;
    border-radius: 10px;
    max-width: 200px;
    min-width: 200px;
}

.service-store-btn {
    background-color: black;
    display: flex;
    padding: 4px;
    width: 110px;
    text-decoration: none;
    border-radius: 5px;
}

.download-btn-left {
    margin: auto auto auto 0;
}

.download-btn-right {
    margin: auto auto auto 0;
}

@media screen and (max-width: 768px) {
    .download-btn-left {
        margin: auto auto auto 0;
    }

    .download-btn-right {
        margin: auto auto auto 0;
    }
}

.service-store-img {
    width: 10px;
    height: 11px;
    margin: auto 5px;
}

.store-img {
    width: 20px;
    height: 22px;
    margin: auto 10px;
}

.service-store-sm-text {
    font-size: 8px;
}

.service-store-lg-text {
    font-size: 10px;
}

.store-sm-text {
    font-size: 10px;
}

.store-lg-text {
    font-size: 17px;
}

.search-address-container {
    min-width: 600px;
    max-width: 100%;
}

.search-container {
    display: flex;
    background-color: white;
    color: #5a6167;
    padding: 14px;
    border: 0.5px solid #DFDFE2;
    border-radius: 10px;
}

.hero-search-icon {
    margin: auto 10px;
    font-size: 12px;
}

.hero-search-bar {
    margin: auto 10px;
    border: none;
    font-size: 12px;
}

.hero-search-bar:focus {
    outline: none;
}

@media screen and (max-width: 992px) {
    .background-image-color {
        right: -15%;
        top: 0;
        width: 800px
    }

    .hero-search-bar {
        font-size: 10px;
    }

    .hero-img {
        width: 420px;
        right: 8%;
        top: 23%;
    }

    .hero-heading-package-detail {
        font-size: 25px;
    }

    .hero-meta-package-detail {
        font-size: 14px;
    }


    .service-table-text {
        font-size: 14px;
        font-weight: 600;
    }
}

@media screen and (max-width: 768px) {
    .header {
        height: 550px;
    }

    /*.cat-header {*/
    /*    height: 550px;*/
    /*}*/
    .hero {
        height: 400px;
    }

    .hero-location-package-detail {
        font-size: 10px;
    }

    .hero-heading-package-detail {
        font-size: 17px;
    }

    .hero-meta-package-detail {
        font-size: 12px;
    }

    .package-detail-hero-btn {
        padding: 6px 10px;
        font-size: 12px;
        border: 1px solid #b9b9b9;
        margin-left: 10px;
    }

    .package-detail-hero-btn > * {
        color: #424B5A;
    }


    .background-image-color {
        right: -20%;
        top: 0;
        width: 600px
    }

    .hero-img {
        width: 350px;
        right: -10%;
        top: 20%;
    }

    .hero-text {
        font-size: 25px;
    }

    .hero-meta {
        font-size: 14px;
        width: 300px;
    }

    .search-container {

    }

    .search-address-container {
        min-width: 400px !important;
    }
}

.red-price {
    font-size: 14px;
    color: red;
    font-weight: 600;
}

.black-price {
    font-size: 14px;
    font-weight: 600;
}

.header-price {
    display: none;
}

.account-liked-service {
    padding: 10px;
    margin: 0 0 10px 0;
    cursor: pointer;
    border-radius: 10px;
    width: 100%;
    display: flex;
    position: relative;
    background-color: white;
}

.account-liked-service-img {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 50px;
    height: 40px;
    border-radius: 10px;
}

.account-liked-controls {
    display: flex;
    margin: auto 10px auto auto;
    position: absolute;
    right: 0;
}

.account-liked-controls-btn {
    background-color: white;
    font-size: 15px;
    font-weight: 500;
    padding: 0 10px;
    color: #5a6167;
    border: 1px solid #5a6167;
    height: 30px;
    margin-left: 10px;
}

.accounts-liked-list {
    max-height: 500px;
    overflow-y: auto;
}

.accounts-liked-list::-webkit-scrollbar {
    width: 5px;
}

.accounts-liked-list::-webkit-scrollbar-thumb {
    background-color: #FFC19A;
}

.accounts-liked-list::-webkit-scrollbar-track {
    background-color: white;
}

.account-liked-services-text-container {
    margin: auto 0 auto 15px;
}

.account-liked-services-text-name {
    font-size: 12px;
    font-weight: 600;
    color: #87494d;
}

.account-liked-services-text-desc {
    font-size: 12px;
    color: #5a6167;
    font-weight: 400;
}

.account-settings-item-container {
    padding: 10px;
    border-radius: 10px;
    background-color: white;
    display: flex;
    margin-bottom: 10px;
}

.account-settings-item-text {
    font-size: 14px;
    font-weight: 600;
    color: #5a6167;
}

.account-settings-item-meta-text {
    font-size: 12px;
    font-weight: 400;
    color: #5a6167;
}

@media screen and (max-width: 492px) {
    .account-settings-item-text {
        font-size: 13px;
        font-weight: 600;
        color: #5a6167;
    }

    .account-settings-item-meta-text {
        font-size: 9.5px;
        font-weight: 400;
        color: #5a6167;
    }

    .account-liked-services-text-name {
        font-size: 11px;
        font-weight: 600;
    }

    .account-liked-services-text-desc {
        font-size: 10px;
        font-weight: 400;
    }

    .account-liked-service-img {
        width: 35px;
        height: 35px;
    }

    .account-liked-controls-btn {
        font-size: 11px;
        font-weight: 400;
        padding: 0 5px;
        height: 20px;
        margin-left: 5px;
    }

    .account-liked-services-text-container {
        margin: auto 0 auto 5px;
    }
}

.application-settings-list {

}

@media screen and (max-width: 492px) {
    .hero-search-icon {
        font-size: 10px;
    }

    .hero-location-package-detail {
        margin-bottom: 10px;
    }

    .header-price {
        display: block;
    }

    .body-price {
        display: none;
    }
}

@media screen and (max-width: 730px) {
    .store-btn-container {
        display: none;
    }
}

.service-rating-heading {
    font-size: 25px;
    font-weight: 700;
    color: #424B5A;
}

.service-rating-meta {
    font-size: 14px;
    font-weight: 400;
    color: #5A6167;
}

@media screen and (max-width: 492px) {

    .service-rating-heading {
        font-size: 17px;
    }

    .service-rating-meta {
        font-size: 10px;
    }

    .terms-header-container-sm {
        padding: 0 35px;
    }

    .header-container-sm {
        padding: 0 12px;
    }

    .header {
        height: 233px;
        background-size: 66%;
    }

    .service-provider-header {
        height: 240px;
    }

    .hero {
        height: 130px;
    }

    .background-image-color {
        right: -50%;
        top: -20%;
        width: 400px
    }

    .hero-img {
        width: 150px;
        right: -0%;
        top: 30%;
    }

    .hero-text {
        font-size: 20px;
        line-height: 1;
    }

    .hero-meta {
        font-size: 10px;
        width: 200px;
        line-height: 1.5;
        margin: 5px 0;
    }

    .search-container {
        font-size: 10px;
        padding: 10px;
        display: none;
    }

    .search-address-container {
        min-width: 315px !important;
        max-width: 325px !important;
    }
}


/*Sidebar*/
.sidebar {
    height: 100vh;
    background-color: #F6F7F9;
    position: absolute;
    z-index: 10;
    right: 0;
    transition: width 0.5s !important;
}

/*.profile-image-container {*/
/*    border-radius: 50%;*/
/*    width: 70px;*/
/*    background-color: purple;*/
/*}*/

.profile-image {
    width: 70px;
}

.sidebar-inactive {
    width: 0;
    padding: 0;
}

.sidebar-active {
    padding: 10px 0;
    width: 300px;
}

.display-item {
    display: block;
    margin: auto 0 auto 10px;
}

.indisplay {
    display: none;
}

.sidebar-item-list {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.sidebar-item-list-active {
    padding: 0 20px;
}

.sidebar-item {
    margin: 15px auto 5px 0;
    font-size: 14px;
    font-weight: 500;
    color: #74787C;
}

/* Offers */

.view-all-btn {
    color: #6CD7BB;
    border: 2px solid #6CD7BB;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    background-color: white;
    margin: auto 0 auto auto;
}

.view-all-btn:hover {
    color: #FFFFFF;
    background-color: #6CD7BB;
}

@media screen and (max-width: 576px) {
    .view-all-btn {
        font-size: 12px;
    }
}

.offers-img-containers {
    display: flex;
    width: 100%;
    overflow-x: hidden;
}

.offer-controllers {
    width: 100%;
}


.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper-sm > .swiper-wrapper > .swiper-slide > div > div > img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiper-sm > .swiper-wrapper > .swiper-slide {
    width: 350px !important;
}

.swiper-sm > .swiper-wrapper > .swiper-slide > div {
    width: 350px;
    height: 215px;
    background-color: white;
}

.accounts-liked-swiper > .swiper-wrapper > .swiper-slide > div {
    background-color: #F6F7F9;
}

.swiper-sm > .swiper-wrapper > .swiper-slide > div > div {
    width: 350px;
    height: 180px;
}

.lazy-loading {
    color: #6CD7BB !important;
}

@media screen and (max-width: 576px) {
    .swiper-sm > .swiper-wrapper > .swiper-slide {
        width: 100% !important;
        height: 100%;
    }
}

.swiper-pagination-bullet-active {
    background-color: #68b6a1 !important;
}

.accordion-item {
    border: none;
}

.accordion-button {
    background-color: #F6F7F9;
    padding: 20px;
    border-radius: 15px;
}

.accordion-button:focus {
    background-color: #F6F7F9;
    box-shadow: none;
}

.accordion-button:not(.collapsed) {
    color: #424b5a;
}

.social-code {
    font-size: 13px;
    margin: auto !important;
}

.copy-btn {
    color: white;
    font-size: 12px;
    margin: auto 0 auto 10px;
    background-color: #6CD7BB;
}

.social-select-container {
    padding: 10px;
    margin-right: auto;
}

.coupon-select {
    font-size: 10px;
}

.code-container {
    background-color: white;
    border-radius: 10px;
    padding: 5px;
    display: flex;
    margin: 0 auto;
    max-width: 250px;
}

.social-background-img {
    width: 100%;
}

.social-icons {
    display: flex;
    width: 100%;
}

.social-icons-container {
    margin: auto;
    display: flex;
    position: relative;
}

.social-icon {
    margin: 0 20px 0 0;
    width: 30px;
}


/*footer*/

.footer-icon {
    width: 30px;
}

.footer-icon-link {
    margin-right: 10px;
    cursor: pointer;
}

.footer-info {
    font-size: 14px;
    font-weight: 500;
    margin: 20px 0 !important;
    line-height: 1.5;
}

.footer-text {
    font-size: 14px;
    font-weight: 500;
    margin: 20px 0 !important;
    line-height: 1.5;
    cursor: pointer;
}

.MuiButtonBase-root {
    text-transform: none !important;
    font-size: 15px !important;
    font-weight: 700 !important;
    margin: 0 30px !important;
}

.service-offer-btn {
    background-color: #6CD7BB;
    color: #FFFFFF;
    font-size: 12px;
    line-height: 1;
    text-transform: capitalize;
}

.service-options {
    width: 100%;
    padding: 15px;
    background-color: #ffc19a;
    border-radius: 10px;
}


.caret {
    color: red !important;
}

.sub-cat-search {
    border: none;
}

.sub-cat-search:focus {
    box-shadow: none;
}

@media screen and (max-width: 576px) {
    .social-code {
        font-size: 10px;
    }

    .socials-row {
        flex-direction: column-reverse;
    }

    .socials-detail-container {
        margin-top: 10px;
    }
}

.bg-illustration {
    left: 30%;
    width: 50%;
    z-index: -10;
    position: absolute;
}

.bg-shape-1 {
    left: 1%;
    top: 20%;
    width: 20%;
    z-index: -10;
    position: absolute;
}

.bg-shape-2 {
    left: 1%;
    width: 30%;
    top: 0%;
    z-index: -10;
    position: absolute;

}

.bg-shape-3 {
    z-index: -10;
    position: absolute;
    bottom: 0;
    left: 40%;
}

.categories-components {
    width: 100%;
    padding: 10px;
    display: flex;
    overflow-x: auto;
}

.all-cat-text {
    padding: 10px;
    background-color: #fff0f7;
    margin: auto 30px;
    height: 130px;
    display: flex;
}


/*package-details*/


/*modal*/

.modal-paper {
    position: absolute;
    width: 700px;
    height: 620px;
    overflow-y: auto;
    background-color: #FFFFFF;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #F6F7FA;
}

.transaction-detail-modal {
    position: absolute;
    width: 400px;
    background-color: #FFFFFF;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #F6F7FA;
}

.popup-modal {
    position: absolute;
    width: 400px;
    background-color: #FFFFFF;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #F6F7FA;
}

.transaction-detail-header {
    color: #1DBF73;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
}

.transaction-detail-key {
    color: #424B5A;
    font-size: 12px;
    font-weight: 600;
    margin-right: auto;
}

.transaction-detail-value {
    color: #5a6167;
    font-size: 12px;
    font-weight: 400;
}

.package-requirement-modal {
    position: absolute;
    width: 700px;
    background-color: #FFFFFF;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #F6F7FA;
}

.package-requirement-modal-body {
    height: 650px;
    overflow-y: auto;
    padding: 0 5px;
}

.package-requirement-modal-body::-webkit-scrollbar {
    width: 5px;
}

.package-requirement-modal-body::-webkit-scrollbar-thumb {
    background-color: #FFC19A;
}

.package-requirement-modal-body::-webkit-scrollbar-track {
    background-color: white;
}

.selected-images-container {
    display: flex;
    width: 100%;
    overflow-x: auto;
}

.selected-image {
    width: 100px;
    height: 90px;
}

.selected-images-container::-webkit-scrollbar {
    height: 5px;
}

.selected-images-container::-webkit-scrollbar-thumb {
    background-color: #6CD7BB;
}

.selected-images-container::-webkit-scrollbar-track {
    background-color: rgba(90, 190, 248, 0.1);
}

.leave-page-paper {
    position: absolute;
    width: 370px;
    overflow-y: auto;
    background-color: #FFFFFF;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #F6F7FA;
}

.register-modal-paper {
    position: absolute;
    width: 1000px;
    height: 420px;
    background-color: #F6F7F9;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #F6F7FA;
}

.checkout-modal-paper {
    position: absolute;
    width: 350px;
    height: 400px;
    overflow-y: auto;
    background-color: #FFFFFF;
    border-radius: 5px;
    border: 1px solid #F6F7FA;
}

.country-number-container {
    display: flex;
    width: 300px;
    padding: 5px 12px;
    border-radius: 5px;
    background-color: white;
    border: 1px solid #dedcdc;
    margin: 15px 0;
}

.mobile-number-container::placeholder {
    color: #C9C9C9;
}

.register-model-text {
    font-size: 50px;
    font-weight: 700;
}

.register-modal-content-container {
    margin: auto;
}

.register-model-meta-text {
    font-size: 15px;
    color: #6CD7BB;
    margin: 0 auto auto auto;
}

.modal-cat-img {
    width: 35px;
    margin: auto 15px auto 0;
}

.modal-cat-text {
    font-size: 14px;
    color: #424B5A;
    margin: auto auto auto 0;
    font-weight: 700;
}

.modal-paper::-webkit-scrollbar {
    width: 5px;
}

.modal-paper::-webkit-scrollbar-thumb {
    background-color: #FFC19A;
}

.modal-paper::-webkit-scrollbar-track {
    background-color: white;
}

.register-modal-img {
    width: 80%;
    margin: 40px auto 0 auto;
}

.register-modal-container {
    padding: 30px 50px;
}

.reg-modal-grid {
    width: 100%;
    height: 100%;
}

.category-modal-navbar {
    display: flex;
    margin: 10px 0 20px 0;
}

.category-modal-navbar-text {
    margin: auto 10px;
    font-size: 20px;
    color: #424B5A;
    font-weight: 700;
}

.category-modal-container {
    padding: 10px 15px;
    cursor: pointer;
    margin: 20px 0;
    background-color: #F6F7F9;
    display: flex;
    border-radius: 10px;
    height: 65px;
    box-shadow: 0 0.5px #d0d7da;
}

@media screen and (max-width: 769px) {
    .transaction-detail-modal {
        width: 360px;
    }

    .modal-cat-text {
        font-size: 14px;
    }

    .category-modal-container {
        padding: 10px 15px;
    }

    .modal-cat-img {
        width: 40px;
    }

    .category-modal-navbar-text {
        margin: auto 10px;
        font-size: 14px;
        color: #424B5A;
    }

    .reg-modal-grid {
        width: 100%;
        height: auto;
    }

    .modal-paper {
        width: 500px;
        height: 350px;
    }

    .package-requirement-modal {
        width: 500px;
    }

    .package-requirement-modal-body {
        height: 350px;
    }

    /*.selected-images-container {*/
    /*    width: 430px;*/
    /*}*/
    .country-number-container {
        width: 100%;
        margin: 10px 0;
    }

    .register-modal-content-container {
        margin: 0 auto auto auto !important;
    }

    .register-modal-container {
        padding: 0;
    }

    .register-modal-paper {
        width: 600px;
        height: 340px;
        padding: 5px 10px;
    }

    .register-modal-img {
        width: 30%;
        margin: 0 auto 0 auto;
    }


    .register-model-meta-text {
        font-size: 14px;
    }
}

.update-cart-btn-sm {
    display: none;
}

.update-cart-btn-bg {
    margin-top: 15px;
}

@media screen and (max-width: 576px) {
    .update-cart-btn-bg {
        display: none;
    }

    .update-cart-btn-sm {
        display: block;
        margin: auto 1px;
    }

    .reg-modal-grid {
        width: 100%;
        height: 90%;
    }

    .modal-paper {
        width: 340px;
        height: 625px;
        padding: 10px;
    }

    .package-requirement-modal {
        width: 340px;
        padding: 5px
    }

    .package-requirement-modal-body {
        height: 600px;
        padding: 2px;
    }

    /*.selected-images-container {*/
    /*    width: 270px;*/
    /*}*/
    .register-model-text {
        font-size: 20px;
        font-weight: 700;
    }

    .register-modal-container {
        padding: 0 15px;
    }

    .register-modal-paper {
        width: 340px;
        height: 650px;
        padding: 10px;
    }

    .register-model-meta-text {
        display: none;
    }

    .register-modal-img {
        width: 70%;
        margin: auto;
    }

    .modal-cat-img {
        width: 40px;
    }

    .modal-cat-text {
        font-size: 12px;
    }
}

.cat-img-container {
    cursor: pointer;
    padding: 17px 0;
    background-color: #F6F7F9;
    display: flex;
    flex-direction: column;
    width: 139px;
    border-radius: 10px;
}

.category-img {
    margin: auto;
    width: 70px;
    height: 75px;
}

.category-text {
    font-size: 13px;
    margin-top: 5px;
    color: #424B5A;
}

.hero-location-lg {
    font-size: 12px;
    margin-right: auto;
}

.hero-location-sm {
    display: none;
    font-size: 9px;
    width: 200px;
    margin-right: 0;
}

@media screen and (max-width: 576px) {
    .hero-location-sm {
        display: block;
    }

    .hero-location-lg {
        display: none;
    }

    .cat-img-container {
        padding: 8px 0;
        width: 102px;
    }

    .category-img {
        width: 65px;
    }

    .category-text {
        font-size: 10px;
        font-weight: 500;
    }
}

.service-img {
    width: 110px;
    height: 67px;
}

.service-name {
    font-size: 14px;
    font-weight: 600;
    color: #424B5A;
}

.service-price-1 {
    color: red;
    margin-right: 10px;
    font-size: 15px;
    font-weight: 600;
}

.service-price-2 {
    font-size: 15px;
    font-weight: 600;
    color: #5A6167;
}

@media screen and (max-width: 576px) {
    /*.service-img {*/
    /*    width: 80px;*/
    /*}*/
    .service-name {
        font-size: 12px;
        font-weight: 600;
    }

    .service-price-1 {
        font-size: 12px;
        margin-right: 15px;
    }

    .service-price-2 {
        font-size: 12px;
    }
}

.MuiTab-root {
    min-width: 0 !important;
}

.MuiTab-wrapper {
    font-family: Poppins !important;
    font-size: 15px;
}

.modal-top-nav {
    display: flex;
    padding: 0 10px;
    margin: 15px 0 0 0;
    cursor: pointer;
}


.consumer-process-modal-map-location {
    width: 22% !important;
    margin: auto;
}

.consumer-process-modal-options {
    margin: auto;
}

.consumer-process-modal-text {
    font-size: 30px;
    font-weight: 700;
    width: 60%;
    line-height: 1.2;
    text-align: center;
    margin: 0 auto 15px auto;
    color: #424B5A
}

.consumer-process-modal-metatext {
    font-size: 11px;
    margin: auto 5px auto auto;
    color: #5A6167;
}

.consumer-process-modal-img {
    width: 40% !important;
    margin: auto;
}

.preference-meta-text {
    /*margin: 5px auto;*/
    font-size: 13px;
    color: #5A6167;
}

.stay-btn {
    background-color: #D86A9D;
    color: white;
    font-size: 10px;
    font-weight: 400;
}

.stay-btn:hover {
    color: white;
}

.leave-btn {
    background-color: #6CD7BB;
    color: white;
    font-size: 10px;
    font-weight: 400;
    margin-right: auto;
}

.leave-btn:hover {
    color: white;
}

.preferences-btn {
    background-color: #6CD7BB;
    color: white;
    font-size: 14px;
    width: 300px;
    font-weight: 500;
    margin-top: auto;
}

.preferences-btn:hover {
    color: white;
}

.preferences-btn:focus {
    background-color: #6CD7BB;
}

@media screen and (max-width: 769px) {

    .preference-meta-text {
        /*margin: 5px auto 0 0;*/
        font-size: 9px;
        text-align: center;
    }

    .consumer-process-modal-text {
        width: 100%;
        font-size: 17px;
        margin: 0 auto 5px auto;
    }

    .consumer-process-modal-img {
        margin: auto;
        width: 35% !important;
    }

    .consumer-process-modal-map-location {
        width: 15% !important;
        margin: 15px auto 10px auto;
    }
}

@media screen and (max-width: 576px) {
    .preference-meta-text {
        font-size: 10px;
        text-align: left;
    }
}

.options-text {
    font-size: 14px;
    font-weight: 600;
    color: #424B5A;
}


.modal-screen {
    display: flex;
    flex-direction: column;
    /*padding: 30px 0 40px 0;*/
    text-align: center;
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 577px) {
    .preferences-btn {
        width: 100%;
    }

    .modal-screen {
        padding: 0px 10px 50px 10px;
        text-align: left;
    }

    .consumer-process-modal-text {
        font-size: 22px;
        width: 100%;
        text-align: left;
        margin-bottom: 20px;
    }

    .consumer-process-modal-metatext {
        font-size: 11px;
        margin: auto auto auto 0;
        text-align: left;
    }

    .consumer-process-modal-img {
        width: 100% !important;
        margin: auto;
    }

    .consumer-process-modal-options {
        margin: auto;
    }


    .options-text {
        font-size: 14px;
        font-weight: 500;
    }

    .consumer-process-modal-map-location {
        width: 45% !important;
        margin: auto;
    }
}

/*.search-box {*/
/*    position: absolute;*/
/*    top: 1rem;*/
/*    left: 15%;*/
/*    font-size: 14px;*/
/*    transform: translateX(-50%);*/
/*    width: 100%;*/
/*    max-width: 400px;*/
/*    z-index: 10;*/
/*}*/

.map-container {
    width: 70%;
    position: relative;
    margin: auto;
}

#map {
    height: 200px;
    width: 100%;
    margin: auto;
}

@media screen and (max-width: 768px) {
    #map {
        height: 100px;
    }

    .map-container {
        width: 100%;
    }
}

@media screen and (max-width: 577px) {
    #map {
        height: 200px;
    }

    /*.search-box {*/
    /*    left: 50%;*/
    /*}*/
    .map-container {
        width: 100%;
    }
}

.tabs-lg {
}

.tab-container {
    margin: 0 auto;
    padding: 10px;
    font-size: 10px;
    width: 100%;
    /*border: 1px solid #74787c;*/
    text-align: center;
    font-weight: 600;
    background-color: #F6F7F9;
}

.tab-active {
    border: 1px solid white;
    background-color: #6CD7BB;
    color: #ffffff;
    font-weight: 700;
}

.cart-tab-active {
    border: 1px solid white;
    background-color: #6CD7BB;
    color: white;
    font-weight: 700;
}

.tabs-sm {
    display: none;
}

@media screen and (max-width: 577px) {
    .tabs-lg {
        display: none;
    }

    .tabs-sm {
        display: flex;
    }
}


.faq-page-sm {
    display: none;
}

.faq-page-lg {
    display: block;
}

@media screen and (max-width: 787px) {
    .faq-page-sm {
        display: flex;
    }

    .faq-page-lg {
        display: none !important;
    }
}

.select-location-dropdown {
    border: none;
    background-color: transparent;
}

.select-location-dropdown:focus {
    outline: none !important;
    box-shadow: none;
}

.coupon-img {
    width: 100%;
    height: 300px;
}

@media screen and (max-width: 1025px) {
    .coupon-img {
        height: 250px;
    }
}

@media screen and (max-width: 426px) {
    .coupon-img {
        height: 200px;
    }
}

.contact-us-form-input {
    width: 330px;
    margin: 15px auto;
    font-size: 12px;
}

.contact-submit-btn {
    color: #FFFFFF;
    background-color: #6CD7BB;
    width: 200px;
    margin: 15px auto;
    font-size: 14px;
}


.orders-container {
    padding: 10px;
    background-color: #F6F7F9;
    border-radius: 5px;
    position: relative;
    margin: 20px 0;
}

.order-detail-text {
    font-size: 12px;
}

.order-detail-meta-text {
    font-size: 12px;
    color: #5a6167;
}

@media screen and (max-width: 426px) {
    .order-detail-text {
        font-size: 10px;
        font-weight: 600;
    }

    .order-detail-meta-text {
        font-size: 8px;
    }
}

.package-add-on-service {
    font-size: 14px;
    color: #424B5A;
    margin-bottom: 3px;
}

.package-add-on-service-subcat {
    font-size: 12px;
    color: #5a6167;
}

.package-add-on-price-1 {
    font-size: 12px;
    color: red;
}

.package-add-on-price-2 {
    font-size: 12px;
    color: #424B5A;
}

.package-add-on-quantity-container {
    display: flex;
    margin: auto 0;
    width: 80px;
    height: 26px;
    padding: 3px 0;
    background-color: white;
}

.quantity-controller {
    margin: auto;
    color: white;
    padding: 0;
    font-size: 14px;
}

.quantity-controller-container {
    width: 20px;
    height: 20px;
    background-color: #6CD7BB;
    display: flex;
    cursor: pointer;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

}

.package-add-img {
    width: 55px;
    margin: auto 0;
}

.quantity {
    font-size: 12px;
}

.package-add-on-services-text-container {
    margin: auto auto auto 10px;
}

.add-on-package-img {
    display: flex;
}

.package-addon-details {
    display: flex;
}

.package-addon-details-lg {
    display: block;
}

.package-addon-details-sm {
    display: none;
    width: 100%;
}

.booking-price-container {
    margin: auto 20px auto auto;
    text-align: right;
}

.booking-prices-container-lg {
    display: flex;
}

.booking-prices-container-sm {
    display: none;
}

.package-add-on-price-container {
    margin: auto 20px auto auto;
    text-align: right;
}

.package-addon-container {
    background-color: #F6F7F9;
    padding: 10px;
}

.package-single-addon-container {
    display: flex;
    margin: 20px 0;
}

@media screen and (max-width: 577px) {
    .package-single-addon-container {
        margin: 10px 0;
    }

    .package-addon-container {
        padding: 5px 10px;
    }

    .package-add-on-price-container {
        margin: auto 7px;
    }

    .booking-prices-container-sm {
        display: flex;
    }

    .booking-prices-container-lg {
        display: none;
    }

    .booking-price-container {
        margin: auto 5px auto auto;
    }

    .package-addon-details-lg {
        display: none;
    }

    .package-addon-details-sm {
        display: block;
    }

    .add-on-package-img {
        padding: 1px;
    }

    .package-add-on-service {
        font-size: 12px;
    }

    .package-add-on-service-subcat {
        font-size: 10px;
    }

    .package-add-on-price-1 {
        font-size: 11px;
    }

    .package-add-on-price-2 {
        font-size: 11px;
    }

    .package-add-img {
        width: 35px;
    }

    .package-add-on-quantity-container {
        width: 80px;
    }

    .quantity-controller-container {
        width: 15px;
    }

    .quantity-controller {
        font-size: 12px
    }

    .quantity {
        font-size: 12px;
    }

    /*.package-add-on-services-text-container {*/
    /*    margin: auto 0 auto 10px;*/
    /*}*/
    .proceed-btn {
        width: 100%;
    }
}

.proceed-btn {
    background-color: #6CD7BB;
    color: white;
    font-size: 14px;
    margin-top: 15px;
}


.MuiBox-root {
    padding: 10px 0 !important;
}

.map-search {
    width: 290px;
    position: absolute;
    z-index: 9999;
    top: 20px;
    left: 20px;
}

.map-modal-container {
    display: flex;
    flex-direction: column;
    padding: 0 10px 30px 10px;
    background-color: #F6F7F9;
}

.address-container {
    height: 300px;
    overflow-y: auto;
}

.address-line1 {
    font-size: 14px;
    font-weight: 600;
    color: #424B5A;
}

.address-line2 {
    font-size: 12px;
    font-weight: 600;
    color: #5A6167;
}

/*232b36*/

.address-detail-container {
    border-radius: 5px;
    cursor: pointer;
    padding: 10px 0;
}

.address-detail-container:hover {
    background-color: #e0e0e5;
}

.address-detail-info-container {
    display: flex;
    width: 100%;
}

.address-detail-info-container:hover {
    /*background-color: #e0e0e5;*/
}

.address-detail-container-active {
    background-color: white;
}

.address-detail-modal-container-active {
    background-color: #F6F7F9;
}

.address-detail-info-container > img {
    width: 15px;
    margin: auto 20px auto 0;
}

.saved-location-modal-subheading {
    font-size: 14px;
    font-weight: 600;
    margin: auto 0;
    color: #424B5A;
}

.locations-star {
    margin: auto 0 auto 0;
    font-size: 28px;
    cursor: pointer;
    outline: #424B5A;
}

.star-selected {
    color: gold;
}

.location-option {
    font-size: 20px;
}

@media screen and (max-width: 768px) {
    .address-container {
        max-height: 300px;
    }

    .saved-location-modal-subheading {
        font-size: 12px;
    }

    .location-option {
        font-size: 14px;
    }

    .locations-star {
        font-size: 25px;
    }

    .address-detail-info-container > img {
        width: 10px;
    }

    .address-line1 {
        font-size: 12px;
        font-weight: 600;
    }

    .address-line2 {
        font-size: 10px;
        font-weight: 400;
    }

    .address-detail-container {
        padding: 3px 0;
        margin-bottom: 10px;
    }

    .map-search {
        top: 10px;
        width: 300px;
    }
}

@media screen and (max-width: 576px) {
    .address-container {
        max-height: 300px;
    }

    .location-option {
        font-size: 20px;
    }

    .address-line1 {
        font-size: 12px;
        font-weight: 600;
    }

    .address-line2 {
        font-size: 10px;
        font-weight: 400;
    }

    .address-detail-info-container > img {
        width: 15px;
        margin: auto 10px auto 0;
    }

    .saved-location-modal-subheading {
        font-size: 14px;
    }

    .address-container {
        padding: 0px;
    }

    .map-search {
        top: 10px;
        left: 7px;
        width: 290px;
    }

    .map-modal-container {
        padding: 0 5px 40px 5px;
    }
}

.locate {
    cursor: pointer;
    position: absolute;
    z-index: 9999;
    border: 1px solid #EEEEEE;
    border-radius: 5px;
    background-color: white;
    padding: 0 5px;
    right: 20px;
    top: 150px;
}

@media screen and (max-width: 768px) {
    .locate {
        top: 65px;
    }
}

@media screen and (max-width: 567px) {
    .locate {
        top: 150px;
    }
}

.map-search > input {
    width: 300px;
}

.places-list {
    padding: 5px;
    position: absolute;
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: white;
    z-index: 999;
    border-radius: 0 0 5px 5px;
    border-top: none;
    border: 1px solid #424b5a;
    text-align: left;
    cursor: pointer;
    width: 300px;
}


.places-list > p {
    font-size: 10px;
    padding: 5px 0;
}

.places-list > p:hover {
    color: #424b5a;
    background-color: #EEEEEE;
    font-weight: 600;
}

.calendar-input {
    font-size: 12px;
    padding: 10px;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    background: url(/src/assets/images/calendar.png)
}

.day-container {
    padding: 10px 15px;
    margin: auto auto auto 0;
    background-color: white;
    border-radius: 5px;
    cursor: pointer;
    border: 2px solid #FFFFFF;
}

.day-container-inactive {
    background-color: #FFFFFF;
    color: #424b5a;
}

.day-container-active {
    border: 2px solid #d86a9d;
    background-color: #d86a9d;
    color: white;
}

.day-container:hover {
    border: 2px solid #d86a9d;
}

@media screen and (max-width: 540px) {
    .day-container {
        padding: 5px 10px;
    }

    .day-container > p {
        font-size: 12px;
    }
}

.account-sidebar {
    width: 100%;
    background-color: #F6F7F9;
}

.account-sidebar-container {
    display: flex;
    color: #6D7073;
    font-size: 14px;
    padding: 20px 30px;
    cursor: pointer;
}

.account-sidebar-item {
    margin: auto 20px auto 0;
}

.account-sidebar-item-inactive {
    background-color: #F6F7F9;
}

.account-sidebar-item-active {
    color: #DB679C;
    background-color: #F1F1F1;
    border-left: 2px solid #DB679C;
}

.cart-heading {
    color: #5a6167;
    font-size: 10px;
    font-weight: 400;
}

.cart-meta-heading {
    color: #202020;
    font-size: 14px;
    font-weight: 500;
}

.cart-dots {
    margin-bottom: 15px;
}

.cart-item-container {
    background-color: #F6F7F9;
    margin-bottom: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 768px) {
    .cart-price {
        display: none;
    }
}

@media screen and (max-width: 540px) {

    .cart-meta-heading {
        font-size: 12px;
    }


    .cart-item-container {
        padding: 10px 0 10px 10px;
    }
}

.cart-price-bot {
    font-size: 13px;
    font-weight: 600;
    margin-left: auto !important;
}

.chat-item-container {
    width: 100%;
    display: flex;
    padding: 30px 20px;
    cursor: pointer;
    margin-bottom: 5px;
}

.chat-item-container-selected {
    background-color: #fafafa;
}

.chat-item-container-not-selected {
    background-color: #f3f2f2;
}

.chat-input {
    border: none;
    margin: auto 0;
}

.chat-input:focus {
    box-shadow: none;
}

.chat-input:placeholder-shown {
    font-style: italic;
}

.chat-sent {
    padding: 10px;
    border-radius: 5px 5px 5px 0;
    color: #5a6167;
    background-color: #FFFFFF;
    width: 400px;
    margin-top: 20px;
    font-size: 12px;
}

.chat-received {
    padding: 10px;
    border-radius: 5px 5px 0 5px;
    color: #FFFFFF;
    background-color: #6BD6BB;
    width: 400px;
    margin-top: 20px;
    margin-left: auto;
    font-size: 12px;
    position: relative;
}

.chat-received:before {
    content: "";
    position: absolute;
    top: 97%;
    right: 0;
    border-top: 10px solid #6BD6BB;
    border-left: 10px solid transparent;
}

.stepper > .MuiPaper-root {
    background-color: #F6F7F9;
    padding: 0;
}

.stepper > .MuiPaper-root > .MuiStep-root > .MuiStepLabel-root > .MuiStepLabel-iconContainer > .MuiStepIcon-active {
    color: #1DBF73;
}

.stepper > .MuiPaper-root > .MuiStep-root > .MuiStepLabel-root > .MuiStepLabel-iconContainer > .MuiStepIcon-completed {
    color: #1DBF73;
}

.stepper > .MuiStepper-vertical > .MuiStep-vertical > .MuiStepLabel-root > .MuiStepLabel-iconContainer > .MuiSvgIcon-root {
    width: 17px;
    height: 17px;
}

.MuiStepIcon-text {
    display: none;
}

.MuiStepLabel-label {
    text-decoration: underline;
    cursor: pointer;
}

.MuiStepConnector-vertical {
    margin-left: 8px !important;
    padding: 3px 0 !important;
}

.MuiStepConnector-lineVertical {
    min-height: 15px !important;
}

.service-execution-address {
    font-size: 14px;
    margin: auto 15px;
    color: #90969A;
}

.order-detail-heading {
    font-size: 13px;
    color: #424B5A;
}

.order-detail-meta-text {
    font-size: 14px;
    color: #424b5a;
}

.reorders-btn {
    padding: 15px;
    font-weight: 500;
    font-size: 14px;
    color: white;
    background-color: #6CD7BB;
    width: 350px;
    margin: 0 auto;
}

.reorders-btn:hover {
    color: white;
}

.orders-btn {
    padding: 15px;
    color: #6CD7BB;
    background-color: white;
    border: 1px solid #6CD7BB;
    width: 350px;
    margin: 15px auto;
}

@media screen and (max-width: 540px) {
    .reorders-btn {
        width: 100%;
    }

    .orders-btn {
        width: 100%;
    }

    .service-execution-address {
        font-size: 12px;
    }

    .service-execution-arrow {
        font-size: 25px;
    }

    .order-detail-heading {
        font-size: 10px;
    }

    .order-detail-meta-text {
        font-size: 12px;
    }
}

.MuiStep-root {
    display: flex;
}

.directions-input {
    width: 280px;
    border: none;
    font-size: 11px;
}

.directions-input:focus {
    box-shadow: none;
}


/*.stepper > .MuiStepper-vertical > .MuiStep-vertical > .MuiStepLabel-root > .MuiStepLabel-iconContainer > .MuiSvgIcon-root {*/
/*    width: 15px;*/
/*    height: 15px;*/
/*}*/

.cart-container {
    padding: 15px;
    background-color: #F6F7F9;
    display: flex;
    margin-bottom: 10px;
}

.page-name {
    font-size: 24px;
    font-weight: 600;
    color: #424B5A;
    margin: auto 0;
}

.page-sub-heading {
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 10px;
    padding-left: 7px;
    color: #424B5A;
}

.page-detail-container > * {
    padding: 0 !important;
}

.package-points {
    font-size: 13px;
    font-weight: 500;
    margin: 5px 0;
    color: #5a6167;
}

.page-list {
    padding-left: 15px;
}

@media screen and (max-width: 576px) {
    .page-list {
        padding-left: 0;
    }
}

.accordion-body {
    padding: 5px 9px;
}

.page-list > ul {
    font-size: 2em;
    list-style: none;
    padding: 0;
}

.page-list > ul > li {
    margin: 0;
    padding-left: 1em;
    position: relative;
}

.page-list > ul > li::after {
    content: '';
    height: .35em;
    width: .35em;
    background: #D39797;
    display: block;
    position: absolute;
    transform: rotate(45deg);
    top: .5em;
    left: 0;
}

.proceed-booking-btn {
    background-color: #6CD7BB;
    color: #FFFFFF;
    margin-left: auto;
    height: 40px;
    display: flex;
    padding: 0 10px;
    border-radius: 5px;
    cursor: pointer;
}

.form-text {
    font-size: 14px;
}

@media screen and (max-width: 576px) {
    .page-name {
        font-size: 17px;
        margin-right: auto;
    }

    .page-sub-heading {
        font-size: 12px;
    }

    .package-points {
        font-size: 12px;
        margin: 12px 0;
    }

    .form-text {
        font-size: 12px;
    }
}

.select-cities {
    background-color: transparent;
    border: none;
    font-size: 14px;
    font-weight: 400;
}

.select-cities:focus {
    box-shadow: none;
    background-color: transparent;
    color: #5a6167;
}

.edit-pencil {
    margin: auto 0 auto 7px;
    color: #424b5a;
    font-size: 14px;
}

.hero-pin {
    width: 14px;
    margin: auto 0;
}

@media screen and (max-width: 576px) {
    .select-cities {
        font-size: 10px;
    }

    .hero-pin {
        width: 10px;
    }

    .edit-pencil {
        font-size: 15px;
    }
}

.country-code-container {
    border: none;
}

/*.country-code-container:focus {*/
/*    box-shadow: none;*/
/*}*/
.mobile-number-container {
    border: none;
    font-size: 12px;
    padding: 5px 10px;
}

.mobile-number-container:focus {
    box-shadow: none;
    border: none;
}

.user-info-btn {
    background-color: #6CD7BB;
    color: white;
    height: 33px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 3px;
    margin-left: auto;
}

.user-info-btn:hover {
    color: white;
}

.user-info-avatar {
    width: 120px;
    border-radius: 50%;
    height: 120px;
    border: 5px solid #6CD7BB;
    background-color: #636364;
}

.accounts-swiper > .swiper-wrapper > .swiper-slide {
    background-color: transparent !important;
}


.location-modal-serviceaddress-container > img {
    width: 5px;
    margin: 0 0 auto 0;
}

.location-modal-serviceaddress-container > .service-address-text {
    font-size: 12px;
    font-weight: 600;
    margin: 0 10px auto 10px;
}

.location-modal-serviceaddress-container > .service-address {
    font-size: 12px;
    font-weight: 400;
    margin-top: 5px;
    text-align: left;
    color: #5A6167;
}

.location-modal-label {
    font-size: 12px;
    font-weight: 600;
    margin-top: 10px;
    color: #424B5A;
}

.location-modal-input {
    font-size: 12px;
    padding: 10px;
}

.location-modal-input::placeholder {
    color: #C9C9C9;
}

@media screen and (max-width: 768px) {
    .location-modal-serviceaddress-container > .service-address {
        margin-top: 0px;
        font-size: 10px;
    }

    .location-modal-label {
        margin-top: 2px;
    }

    .location-modal-label {
        font-size: 10px;
        font-weight: 400;
    }

    .location-modal-input {
        font-size: 10px;
        padding: 8px;
    }
}

@media screen and (max-width: 576px) {
    .cart-container {
        padding: 8px;
    }

    .location-modal-label {
        font-size: 10px;
        font-weight: 400;
        margin-top: 15px;
    }

    .location-modal-input {
        font-size: 10px;
        padding: 8px;
    }


    .location-modal-serviceaddress-container > img {
        width: 10px;
        margin: 0 0 auto 0;
    }

    .location-modal-serviceaddress-container > .service-address-text {
        font-size: 10px;
        font-weight: 400;
        margin: 0 10px auto 10px;
    }

    .location-modal-serviceaddress-container > .service-address {
        font-size: 10px;
        font-weight: 400;
    }

}

.choose-map-location {
    margin: 10px 0 0 0;
    cursor: pointer;
    padding: 10px;
}

.choose-map-location-text {
    font-size: 14px;
    font-weight: 700;
    color: #6CD7BB;
}

.choose-map-location-support-text {
    font-size: 13px;
    font-weight: 400;
    color: #6CD7BB;
}

.choose-map-location-icon {
    margin: auto 15px auto 0;
    font-size: 20px;
    font-weight: 700;
    color: #6CD7BB;
}

.filter-location-text {
    font-size: 14px;
    font-weight: 400;
    color: #6CD7BB;
    text-align: left;
}

@media screen and (max-width: 768px) {
    .choose-map-location {
        margin: 0;
    }

    .filter-location-text {
        font-size: 12px;
    }

    .choose-map-location {
        padding: 2px 10px;
    }

    .choose-map-location-icon {
        font-size: 15px;
    }

    .choose-map-location-text {
        font-size: 12px;
    }

    .choose-map-location-support-text {
        font-size: 10px;
    }
}

@media screen and (max-width: 576px) {
    .choose-map-location {
        padding: 10px 5px;
        margin: 0;
    }

    .choose-map-location-text {
        font-size: 14px;
        line-height: 12px;
    }

    .choose-map-location-support-text {
        font-size: 13px;
    }

    .choose-map-location-icon {
        font-size: 30px;
    }
}

.choose-map-location-container {
    display: flex;
    width: 100%;
    background-color: white;
    padding: 10px 0 10px 10px;
    border-radius: 5px;
}

.set-location-icon {
    font-size: 20px;
    color: white;
}

.location-search-container {
    padding: 2px 10px;
    background-color: white;
    width: 80%;
    border-radius: 5px;
    border: 0.5px solid #e7e2e2;
    margin: auto auto auto 0;
}

.location-search {
    display: flex;
    width: 100%;
    padding: 0 10px;
    margin-bottom: 10px;
}

.location-search-icon {
    margin: auto 0;
}

.location-search-input {
    background-color: #ffffff;
    border: none;
    margin: auto 0;
    font-size: 12px;
}

.location-search-input:focus {
    border: none;
    box-shadow: none;
    background-color: transparent;
}

.right-tabs-container {
    width: 100%;
    padding: 40px;
    background-color: #F6F7F9;
}

.register-modal-img-lg {
    display: block;
}

.register-modal-img-sm {
    display: none;
}

.reg-modal-helper {
    font-size: 12px;
    color: #5A6167;
    margin: 15px 0;
}

.reg-modal-helper-checkbox {
    font-size: 12px;
    color: #5A6167;
}

.enter-contact-text {
    font-size: 14px;
    color: #5A6167;
}

.checkbox-container {
    margin-top: 10px;
    display: flex;
}

@media screen and (max-width: 768px) {
    .location-search-icon {
        font-size: 14px;
    }

    .location-search-input {
        padding: 0 10px;
    }

    .reg-modal-helper-checkbox {
        font-size: 10px;
    }

    .enter-contact-text {
        font-size: 12px;
    }

    .reg-modal-helper {
        font-size: 10px;
        margin: 5px 0;
    }

    .checkbox-container {
        margin-top: 5px;
    }

    .choose-map-location-container {
        padding: 3px 10px;
    }
}

.flag-code-item {
    color: #717171;
    font-size: 15px;
    margin: auto 0;
}

@media screen and (max-width: 768px) {
    .flag-code-item {
        font-size: 12px;
    }

    .right-tabs-container {
        padding: 10px 10px;
    }
}

.open-accounts-sidebar-logo {
    display: none;
}

.accounts-sidebar-sm {
    display: none;
}

.accounts-sidebar-lg {
    display: block;
}

.close-accounts-sidebar-sm {
    height: 0;
    transition: height 0.5s !important;
}

.close-accounts-sidebar-sm > * {
    display: none;
    transition: height 0.5s !important;
}

.open-accounts-sidebar-sm {
    height: 100%;
    transition: height 0.5s !important;
}

.open-accounts-sidebar-sm > * {
    transition: height 0.5s !important;
    display: block;
}

@media screen and (max-width: 576px) {
    .accounts-sidebar-lg {
        display: none;
    }

    .accounts-sidebar-sm {
        display: block;
    }

    .open-accounts-sidebar-logo {
        display: block;
    }

    .open-accounts-sidebar-logo > * {
        display: block;
    }

    .choose-map-location-container {
        padding: 10px;
    }

    .checkbox-container {
        margin-top: 10px;
    }

    .flag-code-item {
        font-size: 12px;
    }

    .enter-contact-text {
        font-size: 10px;
    }

    .reg-modal-helper {
        font-size: 10px;
        margin: 15px 0;
    }

    .reg-modal-helper-checkbox {
        font-size: 9px;
    }

    .right-tabs-container {
        padding: 10px;
    }

    .location-search {
        padding: 0 5px;
    }

    .register-modal-img-lg {
        display: none;
    }

    .register-modal-img-sm {
        display: block;
    }
}

.country-number-container > .MuiInputBase-root > .MuiSvgIcon-root {
    width: 0.7em;
    height: 0.7em;
    top: calc(50% - 9px)
}

.swiper-slide {
    background-color: transparent;
}

.check-swiper {
    background-color: blue;
}


/* Consumer process modal*/

.consumer-process-btn {
    background-color: #6CD7BB;
    color: white;
    font-size: 14px;
    width: 300px;
    font-weight: 500;
    margin: 0 auto 50px auto;
}

.consumer-process-btn:hover {
    color: white;
}

.consumer-process-btn:focus {
    box-shadow: none;
    background-color: #6CD7BB;
}

.reg-modal-img-container {
    padding: 30px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 769px) {
    .reg-modal-img-container {
        padding: 5px;
    }
}

@media screen and (max-width: 577px) {
    .reg-modal-img-container {
        padding: 30px;
    }

    .country-number-container {
        margin: 15px 0;
    }
}

.swiper-modal-paper {
    width: 1000px;
    height: 600px;
    background-color: #F6F7F9;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #F6F7FA;
    position: absolute;
}

.share-modal {
    width: 400px;
    padding: 10px;
    border-radius: 5px;
    position: absolute;
    background-color: #F6F7F9;
}

@media screen and (max-width: 577px) {
    .share-modal {
        width: 370px;
    }
}

.share-btn-icons {
    margin-right: 10px;
}

@media screen and (max-width: 768px) {
    .swiper-modal-paper {
        width: 650px;
        height: 360px;
    }
}

@media screen and (max-width: 768px) {
    .consumer-process-btn {
        font-size: 10px;
        padding: 5px;
        width: 190px;
        margin: 0 auto 30px auto;
    }
}

@media screen and (max-width: 577px) {

    .swiper-modal-paper {
        width: 340px;
        height: 650px;
    }

    .consumer-process-btn {
        margin: auto auto 60px auto;
        width: 99%;
    }
}


/* Preference modal slider */

.options-container {
    width: 100%;
    height: 100%;
    padding: 25px;
    border-radius: 5px;
    background-color: #FFFFFF;
}

@media screen and (max-width: 768px) {
    .options-container {
        width: 100%;
        padding: 10px 25px;
    }

    .options-text {
        font-size: 10px;
    }
}

.active-option {
    background-color: #6CD7BB;
}

.active-option > p {
    color: #ffffff !important;
}

.preference-option-container {

}

.preferences-inner-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 577px) {
    .options-text {
        font-size: 12px;
    }

    .options-container {
        width: 100%;
        padding: 10px;
    }

    .preference-option-container {
        padding: 0 1px;
    }

    .preferences-inner-container {
        width: 318px;
    }
}


/* location choice container */

.location-choice-inner-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.choose-location-btn {
    background-color: #6CD7BB;
    color: white;
    font-size: 14px;
    width: 300px;
    font-weight: 500;
    margin: 0 auto;
}

.choose-saved-location-btn {
    background-color: #ffffff;
    color: #5A6167;
    border: 1px solid #5A6167;
    margin-top: 10px;
    margin-bottom: 50px;
}

.choose-location-btn:hover {
    color: white;
}

.choose-location-btn:focus {
    background-color: #6CD7BB;
}

.choose-saved-location-btn:hover {
    color: #5A6167;
}

@media screen and (max-width: 768px) {
    .choose-location-btn {
        font-size: 12px;
        width: 200px;
        margin-top: 15px;
    }

    .choose-saved-location-btn {
        font-size: 12px;
        width: 200px;
        margin-top: 5px;
        margin-bottom: 60px;
    }
}

@media screen and (max-width: 577px) {
    .choose-location-btn {
        font-size: 14px;
        width: 300px;
    }

    .choose-saved-location-btn {
        font-size: 14px;
        width: 300px;
    }
}

.choose-saved-location-btn:focus {
    background-color: white;
}

.question-faq-icon {
    font-size: 18px;
}

.question-faq-icon-package {
    font-size: 24px;
    margin: auto 0 auto 10px;
    cursor: pointer;
}

.question-faq-icon-choice-modal {
    margin: auto auto auto 0;
}

@media screen and (max-width: 577px) {
    .location-choice-inner-container {
        width: 318px;
    }

    .question-faq-icon {
        font-size: 40px;
    }
}


/* Select Location */

.faq-icon-selection-location {
    color: #ce5e97;
    margin: auto 10px;
    font-size: 28px;
}

.package-requirements-modal {
    margin: auto 0 auto auto;
}

.booking-heading {
    font-size: 22px;
    font-weight: 600;
}

.booking-faq {
    margin: auto 10px;
}

.select-location-pin {
    width: 15px;
    margin: auto 0;
}

.display_title {
    font-size: 14px;
    font-weight: 700;
    margin-left: 5px;
    color: #424B5A;
}

@media screen and (max-width: 768px) {
    .display_title {
        font-size: 10px;
        font-weight: 600;
        margin-left: 5px;
    }

    .select-location-pin {
        width: 10px;
    }
}

@media screen and (max-width: 567px) {
    .select-location-pin {
        width: 12px;
    }
}

.location-modal-saved-locations-btn {
    width: 120px;
    color: #6CD7BB;
    background-color: white;
    border: 1px solid #6CD7BB;
    font-size: 12px;
    margin: auto 2px auto auto;
}

.location-modal-saved-locations-btn:hover {
    color: #6CD7BB;
}

.location-modal-save-btn-container {
    width: 100%;
    display: flex;
    margin-top: 20px;
}

.location-modal-save-btn {
    margin-left: auto;
    background-color: #6CD7BB;
    color: white;
    font-size: 12px;
    font-weight: 600;
    width: 120px;
}

.location-modal-save-btn:hover {
    color: white;
}

.location-modal-save-btn:focus {
    background-color: #6CD7BB;
}

@media screen and (max-width: 768px) {
    .location-modal-save-btn {
        font-size: 10px;
        font-weight: 400;
    }

    .location-modal-save-btn-container {
        margin-top: 0;
    }

    .location-modal-saved-locations-btn {
        font-size: 9px;
        font-weight: 400;
        margin: auto 0 auto auto;
    }

    .faq-icon-selection-location {
        font-size: 25px;
    }
}

@media screen and (max-width: 577px) {
    .location-modal-save-btn-container {
        margin-top: 15px;
    }

    .booking-heading {
        font-size: 17px;
        font-weight: 600;
    }

    .faq-icon-selection-location {
        font-size: 26px;
    }

    .booking-faq {
        margin: auto 0 auto auto;
    }

    .location-modal-saved-locations-btn {
        font-size: 10px;
        font-weight: 400;
        margin: auto 2px auto auto;
    }

    .location-modal-save-btn {
        font-size: 10px;
        font-weight: 600
    }
}


/* Saved location screen */

.faq-icon-saved-location-modal {
    color: #D86A9D;
    margin: auto 0 auto auto;
    font-size: 27px;
}

.hr-line {
    margin: 15px 0;
}

.address-info-container {
    width: 100%;
    display: flex;
    margin-top: 20px;
}

.display-location-lg {
    display: block;
}

.display-location-sm {
    display: none;
}

.saved-address-pin {
    width: 30px;
}

@media screen and (max-width: 768px) {
    .faq-icon-saved-location-modal {
        font-size: 17px;
    }

    .display-location-lg {
        display: block;
    }

    .hr-line {
        margin: 5px 0;
    }

    .address-info-container {
        margin-top: 4px;
    }
}

.share-earn-question-icon {
    color: #D86A9D;
    margin: auto 0 auto 10px;
    font-size: 27px;
}

.accounts-question-icon {
    color: #D86A9D;
    margin: auto 0 auto 10px;
    font-size: 27px;
}

.alert-question-icon {
    color: #D86A9D;
    font-size: 27px;
    margin: auto 0;
}

@media screen and (max-width: 577px) {
    .alert-question-icon {
        font-size: 30px;
    }

    .share-earn-question-icon {
        margin: auto 0 auto 10px;
        font-size: 30px;
    }

    .accounts-question-icon {
        margin: auto 0 auto auto;
        font-size: 30px;
    }

    .faq-icon-saved-location-modal {
        margin: auto 0 auto auto;
        font-size: 30px;
    }

    .hr-line {
        margin: 15px 0;
    }

    .display-location-lg {
        display: none;
    }

    .display-location-sm {
        display: block;
        margin-top: 15px;
    }

    .display-location-sm > .service-address {
        margin-top: 5px;
    }

    .address-info-container {
        margin-top: 15px;
    }
}


.check-container {
    display: block;
    position: relative;
    padding-left: 20px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.check-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: -1.5px;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #ffffff;
    border: 2px solid #5A6167;
    border-radius: 2px;
}

/* On mouse-over, add a grey background color */
.check-container:hover input ~ .checkmark {
    background-color: #ffffff;
}

/* When the checkbox is checked, add a blue background */
.check-container input:checked ~ .checkmark {
    background-color: #ffffff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.check-container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.check-container .checkmark:after {
    left: 4px;
    top: 0.5px;
    width: 4px;
    height: 9px;
    border: solid #5A6167;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* Booking */

.booking-address-icon {
    width: 12px;
    margin: auto 0 auto 0;
    cursor: pointer;
}

.booking-address-heading {
    margin: auto auto auto 10px;
    font-size: 12px;
    font-weight: 600;
    color: #424B5A;
}

.booking-address-text {
    font-size: 12px;
    color: #5a6167;
    margin: 0 0 10px 0;
}

.booking-address-container {
    margin: 15px 0;
}

.cart-package-name {
    font-size: 14px;
    font-weight: 600;
    margin: auto auto auto 10px;
}

.cart-chat-img {
    margin: auto 0 auto 10px;
    width: 15px;
    height: 15px;
    cursor: pointer;
}

.cart-chat-id {
    font-size: 14px;
    margin: auto 40px auto 15px;
}

.cart-dots {
    font-size: 17px;
}

.cart-right-arrow {
    margin: auto;
    font-size: 14px;
    cursor: pointer;
    stroke-width: 2
}

.order-detail-right-arrow {
    margin: auto;
    font-size: 10px;
    cursor: pointer;
    stroke-width: 1
}

.alert-right-arrow {
    margin: 0 0 0 10px;
    font-size: 15px;
    cursor: pointer;
}


.cart-detail-heading {
    font-size: 13px;
    color: #424B5A;
}

.cart-detail-meta-text {
    font-size: 12px;
    color: #5a6167;
}

.booking-main-img {
    display: none;
}

.alert-dots {
    margin: 0 auto 5px auto;
    padding: 0 10px;
}

@media screen and (max-width: 577px) {
    .booking-address-text {
        font-size: 10px;
    }

    .booking-main-img {
        display: block;
    }

    .cart-dots {
        margin-bottom: 12px;
    }

    .cart-package-name {
        font-size: 11px;
    }

    .cart-chat-img {
        width: 10px;
        height: 10px;
    }

    .cart-chat-id {
        font-size: 10px;
        font-weight: 600;
        margin: auto 0px auto 15px;
    }

    .cart-dots {
        font-size: 17px;
    }

    .cart-right-arrow {
        margin: 2px 0 0 0;
        font-size: 12px;
    }

    .cart-detail-heading {
        font-size: 11px;
        color: #424B5A;
    }

    .cart-detail-meta-text {
        font-size: 10px;
        color: #5a6167;
    }

}

.proceed-to-order-btn {
    color: white;
    background-color: #6CD7BB;
    font-size: 12px;
    font-weight: 600;
    padding: 10px;
}

.proceed-to-order-btn:hover {
    color: white;
}

.booking-packing-heading {
    font-size: 14px;
    font-weight: 700;
    text-decoration: underline;
    color: #424B5A;
    cursor: pointer;
}

.booking-package-edit-icon {
    width: 12px;
    margin: auto 0 auto auto;
    cursor: pointer;
}

.schedule-info-text {
    font-size: 12px;
    font-weight: 600;
    color: #424B5A;
}

.schedule-info-detail {
    font-size: 12px;
    color: #5a6167;
}

.total-booking {
    margin-right: auto;
    font-size: 12px;
    font-weight: 600;
    color: #424B5A;
}

.total-booking-text {
    font-size: 12px;
    color: #5a6167;
}

.total-saving-text {
    font-size: 10px;
    color: #424B5A;
}

.apply-coupon-text {
    font-size: 12px;
    margin: auto auto auto 0;
    color: #424B5A;
}

.booking-service-text {
    font-size: 14px;
    font-weight: 600;
    color: #424B5A;
}

.booking-confirm-btn {
    background-color: #6CD7BB;
    color: white;
    font-size: 14px;
    font-weight: 500;
}

.booking-package-name {
    font-size: 14px;
    font-weight: 600;
    color: #424B5A;
}

.booking-package-shortDescription {
    color: #5a6167;
    font-size: 12px;
    margin-right: auto;
}

.booking-base-price {
    font-size: 12px;
    color: red;
}

.booking-selling-price {
    font-size: 12px;
    color: #424B5A;
}

@media screen and (max-width: 577px) {
    .booking-selling-price {
        font-size: 10px;
    }

    .booking-base-price {
        font-size: 10px;
    }

    .booking-package-name {
        font-size: 12px;
    }

    .booking-packing-heading {
        font-size: 12px;
    }

    .booking-package-shortDescription {
        font-size: 10px;
    }

    .booking-confirm-btn {
        font-size: 12px;
        font-weight: 400;
    }

    .booking-service-text {
        font-size: 12px;
    }

    .schedule-info-text {
        font-size: 10px;
    }

    .schedule-info-detail {
        font-size: 9px;
    }

    .total-booking {
        font-size: 12px;
    }

    .total-booking-text {
        font-size: 11px;
    }

    .apply-coupon-text {
        font-size: 10px;
    }
}

.alert-search {
    font-size: 12px;
    padding: 10px;
    margin: 10px 0;
}

.alert-options-container {
    margin: 10px 0;
}

.filter-alert-text {
    font-size: 12px;
    color: #5a6167;
    width: 200px;
    font-weight: 600;
}

@media screen and (max-width: 577px) {
    .filter-alert-text {
        font-size: 10px;
        width: 100%;
    }
}

.alert-select {
    font-size: 12px;
}

.alert-container {
    padding: 10px;
    margin: 0 0 12px 0;
    width: 100%;
    display: flex;
    cursor: pointer;
}

.alert-container-unread {
    background-color: #F6F7F9;
}

.alert-container-read {
    background-color: #fdfcfc;
}

.alert-info-container {
    display: flex;
    width: 100%;
}

.alert-text {
    font-size: 14px;
    color: #424B5A;
}

.alert-date {
    font-size: 12px;
    color: #424B5A;
}

.alert-description {
    font-size: 12px;
    color: #5a6167;
    margin-top: 2px;
}

.alert-page-container {
    margin-top: 50px;
    margin-bottom: 50px;
}

.reset-btn-lg-container {
    display: block;
}

.reset-btn-sm-container {
    display: none;
}

@media screen and (max-width: 577px) {
    .reset-btn-lg-container {
        display: none;
    }

    .reset-btn-sm-container {
        display: block;
    }

    .alert-page-container {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .alert-container {
        margin: 10px 0;
    }
}


.category-search-container {
    display: flex;
    background-color: white;
    color: #5a6167;
    border: 0.5px solid #DFDFE2;
    border-radius: 10px;
    width: 300px;
    margin-right: auto;
}

.sub-category-filter-block-lg {
    display: flex;
}

.sub-category-filter-block-sm {
    display: none;
}

.service-container {
    width: 150px;
    font-size: 10px;
    margin: 0 10px;
}

.service-container-right {
    width: 150px;
    font-size: 10px;
}

.service-select {
    min-width: 50px !important;
    font-size: 14px;
    color: #5B5D61;
    fill: #5B5D61;
    padding: 5px;
    border: 2px solid #5B5D61;
    border-radius: 5px;
    font-weight: 700;
}

/*#5a6167*/
.service-at-home {
    color: #fa630f;
    fill: #fa630f;
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='orange'><path fill-rule='red' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
    border: 1px solid #fa630f;
}

.home-type {
    color: #5a6167;
    fill: #5a6167;
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='Vampire Gray'><path fill-rule='red' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
    border: 1px solid #5a6167;
}

.sub-cat-heading {
    font-size: 30px;
    font-weight: 700;
    margin-right: 15px;
}

.sub-category-filter-container {
    margin-top: 50px;
    margin-bottom: 50px;
}

.package-add-on-text {
    font-size: 14px;
    font-weight: 600;
}

.join-us-heading-container {
    width: 100%;
    text-align: center;
}

.join-us-heading {
    font-size: 30px;
    font-weight: 700;
    color: #33bbcb;
}

.join-us-meta-text {
    font-size: 20px;
    font-weight: 600;
    color: #33bbcb;
}

.join-us-item-container {
    display: flex;
    margin-top: 15px;
}

.join-us-icon {
    color: #33bbcb;
    margin: auto 20px auto 0;
    font-size: 22px;
    font-weight: 500;
}

.join-us-item-text {
    color: #33bbcb;
    margin: auto 0;
    font-size: 17px;
    font-weight: 500;
}

.join-btn-container {
    margin: 0 0 0 auto;
}

.filter-drop-down-container {
    display: flex;
}

@media screen and (max-width: 577px) {
    .filter-drop-down-container {
        margin-top: 10px;
    }

    .join-btn-container {
        margin: 0;
    }

    .join-us-heading-container {
        width: 100%;
        text-align: left;
    }

    .join-us-heading {
        font-size: 26px;
    }

    .join-us-meta-text {
        font-size: 17px;
    }

    .join-us-item-text {
        font-size: 15px;
    }

    .package-add-on-text {
        font-size: 12px;
    }

    .sub-category-filter-container {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .sub-category-filter-block-lg {
        display: block;
    }

    .sub-category-filter-block-sm {
        display: block;
    }

    .service-container {
        width: 120px;
    }

    .service-container-right {
        width: 120px;
        margin-right: auto;
    }

    .service-select {
        font-size: 11px;
    }

    .category-search-container {
        width: 100%;
        padding: 10px;
        font-size: 12px;
    }

    .sub-cat-heading {
        font-size: 22px;
        font-weight: 700;
    }
}

.provide-requirement-link {
    margin: auto auto auto 0;
    font-size: 12px;
    font-weight: 700;
    text-decoration: underline;
}

.btn-provide-requirement {
    font-size: 10px;
    font-weight: 700;
    padding: 5px;
}

.complete-status {
    border: 2px solid #77B47A;
    color: #77B47A;
}

.incomplete-status {
    border: 2px solid #D86A9D;
    color: #D86A9D;
}

@media screen and (max-width: 577px) {
    .btn-provide-requirement {
        font-size: 10px;
    }

    .complete-status {
        border: 2px solid #77B47A;
    }

    .incomplete-status {
        border: 2px solid #D86A9D;
    }
}


.btn-provide-requirement-status:hover {
    color: #77B47A;
}

.package-detail-hero-lg {
    display: block;
    margin: 50px 0;
}

.package-detail-hero-sm {
    display: none;
}

@media screen and (max-width: 577px) {
    .package-detail-hero-lg {
        display: none;
    }

    .package-detail-hero-sm {
        display: block;
    }
}

.package-image-container {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 40px;
    height: 30px;
    border-radius: 10px;
}

.alert-image-container {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 60px;
    height: 40px;
    border-radius: 10px;
}

.provide-requirements-image-container {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 80px !important;
    height: 60px !important;
    border-radius: 10px;
    margin-right: 10px;
}

.category-modal-category-promotion-text {
    font-size: 10px;
    font-weight: 700;
}

.service-img-container {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 80px;
    height: 60px;
    border-radius: 10px;
}

.coupon-options {
    font-weight: 600;
    font-size: 12px;
}

@media screen and (max-width: 577px) {
    .coupon-options {
        font-weight: 600;
        font-size: 9px;
    }

    .service-img-container {
        width: 60px;
        height: 60px;
    }
}

.contact-us-paralax {
    background-image: url('./assets/images/contactus.JPG');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 60vh;
    margin: 30px 0;
}

.contact-us-banner-heading {
    font-size: 48px;
    font-weight: 600;
}

.contact-us-banner-text {
    font-size: 24px;
    font-weight: 600;
}

@media screen and (max-width: 577px) {
    .contact-us-banner-heading {
        font-size: 32px;
        font-weight: 600;
    }

    .contact-us-banner-text {
        font-size: 16px;
        font-weight: 600;
    }

    .contact-us-paralax {
        background-attachment: inherit;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 100%;
        height: 200px;
        margin: 10px 0;
    }
}

.about-us-paralax {
    background-image: url('./assets/images/aboutus.JPG');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 60vh;
    margin: 30px 0;
}

.mid-parallax-lg-text {
    margin: auto;
    width: 70%;
    text-align: center;
    font-size: 48px;
    font-weight: 600;
    color: white;
}

@media screen and (max-width: 577px) {
    .about-us-paralax {
        background-attachment: initial;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 100%;
        height: 200px;
        margin: 10px 0;
    }

    .mid-parallax-lg-text {
        font-size: 36px;
    }
}

.mid-parallax {
    background-image: url('./assets/images/Why-Clykk-Background.jfif');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    min-height: 80vh;
    display: flex;
    margin: 30px 0;
    padding: 10px 0;
}

.icon-container {
    width: 110px;
    height: 110px;
    line-height: 110px;
    background-color: rgba(255, 255, 255, 0.07);
    border-style: solid;
    border-color: rgb(204, 204, 204);
    border-width: 1px;
    border-radius: 50%;
    display: flex;
    margin: auto;
}

.contact-us-btn {
    width: 300px;
    background-color: #6b74e6;
    padding: 10px;
    border-radius: 20px;
    color: white;
    font-weight: 400;
    font-size: 15px;
}

.contact-us-icon {
    color: white !important;
    font-size: 54px;
    margin: auto;
}

.contact-icon-name {
    color: white;
    text-align: center;
    margin: 25px 0;
    font-size: 22px;
    font-weight: 500;
}

.contact-icon-meta {
    color: white;
    text-align: center;
    font-size: 15px;
}


.package-requirements-heading {
    font-size: 17px;
    font-weight: 600;
}

.package-requirement-container {
    background-color: #F6F7F9;
    padding: 10px;
    border-radius: 5px;
}


@media screen and (max-width: 577px) {
    .package-requirements-heading {
        font-size: 14px;
        font-weight: 600;
    }
}

.join-us-btns-container {
    display: none;
}

@media screen and (max-width: 577px) {
    .join-us-btns-container {
        display: flex;
        margin: 15px 0 5px 0;
    }

    .terms-text-container {
        padding: 0 32px;
        margin: 20px 0 0 0;
    }
}

.terms-text-container > p {
    color: #5a6167;
    line-height: 26px;
    font-size: 14px;
    white-space: break-spaces;
    margin: 10px 0;
    font-weight: 300;
}

.terms-headings {
    font-size: 15px !important;
    color: #5a6167 !important;
    font-weight: 500 !important;
}

.additional-charges-bill-container {
    background-color: #F6F7F9;
    padding: 10px;
    border-radius: 5px;
}

.material-bill {
    font-size: 12px;
    width: 200px;
}

.description-charges {
    font-size: 12px;
}

.tip-container {
    padding: 5px;
    width: 100%;
    color: black;
    font-size: 12px;
    font-weight: 400;
    border-radius: 20px;
    border: 1px solid #6CD7BB;
    text-align: center;
    cursor: pointer;
}

.no-thanks-btn {
    width: 120px;
}

.selected-tip-container {
    color: white;
    background-color: #6CD7BB;
}

.calender-item {
    margin: auto;
    display: flex;
    flex-direction: column;
    border: 1px solid #5a6167;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
}

.calender-text {
    font-size: 11px;
    font-weight: 600;
    margin: 0 auto;
}

.calender-logo {
    margin: 10px auto;
    width: 45px;
}

.modal-blocker-paper {
    position: absolute;
    width: 300px;
    height: 160px;
    overflow-y: auto;
    background-color: #FFFFFF;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #F6F7FA;
}

.block-allow {
    font-size: 10px;
    font-weight: 600;
    margin: 0 auto;
    width: 100px;
}

.block-allow-accept {
    color: white;
    background-color: #6CD7BB;
}

.block-allow-accept:hover {
    color: white;
}
